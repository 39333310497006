import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";


import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light.js";

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-800 hover:text-gray-800`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-800 hover:text-primary-500`}
  }
`;


export default () => {

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about">
        About
      </NavLink>
     <NavLink href="/faq">
        FAQs
      </NavLink>
      <NavLink href="/privacy">
        Policy
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/#contactus">
        Hire Us
      </PrimaryLink>
    </NavLinks>
  ];


  return (
    <AnimationRevealPage>
      <HeroContainer>
      <StyledHeader links={navLinks} />
      <MainFeature1
        subheading={<Subheading>About Sellectric Tech Labs</Subheading>}
        heading="We are a modern design agency."
        buttonRounded={false}
        description = "Sellectric puts together a bunch of passionate minds to transform your visions into reality imparting an awesome digital experience for your users. From website design, development, scalable mobile apps to SaaS platforms, the solution we engineer accelerate efficiency and fuel growth for our clients.       Our team is made up of award-winning marketers, designers, and developers and we know what it takes to get real results online. We also keep the focus on the metrics that mean the most, like leads and revenue generated. We know that hitting these goals is what moves businesses forward, and we believe that our clients' success is the best measure of our own performance."
        primaryButtonText="Contact Us"
        primaryButtonUrl="/#contactus"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to disrupt the design space."
        buttonRounded={false}
        description="We are committed to achieving and pushing the level of performance at all times. Leveraging the power of technology, we deliver end-to-end solutions for multi-million dollar funded start-ups to small and medium enterprises. Art and culture inspire us too, and our office presence at Karnataka and Kerala is a true reflection. The Internet is our passion, and we’re passionate about helping businesses reach their goals. So when you choose Sellectric, you won’t get a cookie-cutter strategy — you'll get a custom plan that fits your company, your needs, and your goals."        
        primaryButtonText="Contact Us"
        primaryButtonUrl="/#contactus"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description=" "
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: " "
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: " "
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: " "
          },
        ]}
        linkText=""
      />
      </HeroContainer>
      <Footer />
    </AnimationRevealPage>
  );
};
