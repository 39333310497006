import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import background from "../../images/bg2.jpg";

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-800 hover:text-gray-800`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const Container = styled.div`
  ${tw`bg-fixed relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("${background}");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-gray-900 sm:text-4xl lg:text-4xl xl:text-5xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

// const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-800 border-l-4 border-blue-500 font-medium text-sm`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about">
        About
      </NavLink>
     <NavLink href="/faq">
        FAQs
      </NavLink>
      <NavLink href="/privacy">
        Policy
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="#contactus">
        Hire Us
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>   
          <LeftColumn>
            {/* <Notification>We have now launched operations in United States.</Notification> */}
            <Heading>
              <span>Custom Application Development</span>
              <br />
              <SlantedBackground>Services</SlantedBackground>
            </Heading>
          </LeftColumn>
          <RightColumn>
            <Heading>
              <span>Digital Marketing that drives</span>
              <br />
              <SlantedBackground>Results</SlantedBackground>
            </Heading>
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
