import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Faq from "components/faqs/SingleCol.js"
import Footer from "components/footers/MiniCenteredFooter.js";

import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../components/headers/light.js";

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-800 hover:text-gray-800`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-800 hover:text-primary-500`}
  }
`;


export default () => {

  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/about">
        About
      </NavLink>
     <NavLink href="/faq">
        FAQs
      </NavLink>
      <NavLink href="/privacy">
        Policy
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/#contactus">
        Hire Us
      </PrimaryLink>
    </NavLinks>
  ];

  const defaultFaqs = [
    {
      question: "How do I get started?",
      answer:
        "Book an appointment with any of our executives and we’ll get on a call with you. Let us know all your requirements along with the level of expertise and experience preferred. We will set you up with the best resources and help you get started."
    },
    {
      question: "Will the teams report monthly work progress and analytics?",
      answer:
        "The teams will share detailed reports on monthly and even a weekly report if needed. They will also track results and share analytics with you on a continuous basis."
    },
    {
      question: "Can you use the graphics / UI which I have created?",
      answer:
        "Yes, we can. You can hire us for just writing codes for programming."
    },
    {
      question: "What is the average time you take to develop a website?",
      answer:
        "The overall time to develop a website depends on several factors. The first one is the complexity of the project. A static website usually takes less time than eCommerce websites or websites that require flash development. Some minor or major changes may increase the time limit. An average time taken by our web developer for a static website will be lesser than a website with flash and other dynamic graphics. Nevertheless, to develop complex websites such as eCommerce website takes a bit more time."
    },
    {
      question: "What is SEO?",
      answer:
        "SEO stands for search engine optimization, and while there are many detailed explanations, the best one we can think of is “making things appear higher in Google (or any other search engine)”. In our case, we also break SEO into on-site and off-site SEO. On-site SEO works on things on your own site that will help search engines understand what you do and find all the pages on your site. Off-site SEO is concerned with creating links back to your site in order to help demonstrate relevance and popularity to Google.        It is obviously more technical than that, but we reckon these are good summaries."
    },
    {
      question: "Do you offer mobile device compatible website?",
      answer:
        "Of course, we do! It is known as Responsive Web Designing and Development. Hire web developers from Sellectric, who have hands-on experience on the latest technologies based on HTML5 and CSS3. We develop eye-catching and graphically convincing web designs that fit even on mobile devices. Nevertheless, you may have to specify this in your objectives before the website or web app development project begins."
    },
    {
      question: "Why do you always talk about Google, there are other search engines?",
      answer:
        "In the majority of countries in the world Google has the highest search engine market share. This is especially true in the UK, where its market share is regularly over 90% of all search traffic to sites. As a result, we tend to spend most of our time working on optimising sites for Google." +
        "Luckily though, a lot of the tactics we use will have a similar effect in the other search engines." +
        "If we work work in countries in which other search engines are dominant, we will tailor our approach to make sure you get the best possible results."
    },
    {
      question: "How do you test the project?",
      answer: "We have a special department (QA & QC) to test projects. We follow the series of below mentioned robust testing for the projects we develop. 1. GUI testing 2. Cross browser testing 3. Black box testing 4. Navigation testing 5. Network testing 6. System testing 7. Unit and integration testing 8. Functional testing 9. Testing on various devices and platforms. Before the project is ready for the above mentioned testing, we parse the code using special software and also manually. Our special programmers leave no stone unturned in screening the codes before executing them for the final version."
    },
    {
      question: "Do you only use Google AdWords?",
      answer: "While the majority of our PPC clients will make use of AdWords, it is not the only advertising platform and certainly isn’t always the most effective. At Sellectric, we also have experience using all sorts of PPC services, such as Facebook Advertising, LinkedIn Advertising and Paid Stumbles, to name a few." +
        "We will help you find the correct advertising platform for what you’re trying to achieve."
    },
    {
      question: "Do you develop mobile app for all platforms?",
      answer: "Yes, we build mobile applications for all major mobile development platforms. You can hire our developers for developing an app for iOS (iPhone and iPad), Android, BlackBerry, Symbian, and Windows Mobile. Whether you want to develop apps for just one or two platforms or want to develop an app that works on all the mobile platforms, we are at your service."
    },
    {
      question: "What is local SEO?",
      answer: "Local SEO focuses on achieving results in location-based searches, for example, “restaurant in New York”. These types of searches are becoming a lot more frequent and often result in a map being displayed in the search results. There are some very specific tactics that can be employed to affect performance in the location-based search results"
    },
    {
      question: "Suppose you develop app for me, what about the revenue this may generate?",
      answer: "We don’t have control over this. Nevertheless, most of the applications developed by Sellectric do well on the market. If you are keen to earn out of the application, we recommend using our Business Analysis provided by the experts. Sellectric offers consultancies in such cases."
    },
    {
      question: "How long will it take to see results?",
      answer: "This is another tough question, as all projects are different and timescales may often be out of our control (for example, a client’s developer may have no time to work on our recommendations). Our stock answer is that within the first 60 days from the start of the projects, most, if not all, clients will have seen a noticeable improvement."
    },
    {
      question: "Are you open to go for App development on partnership basis?",
      answer: "Yes, we are. We have a professional partnership model for clients who want to grow in business with us. We have been in collaboration with partners from multiple global locations with whom we have established a long term vision and business relationship."
    },
    {
      question: "Which framework or programming language would you use to develop a mobile application?",
      answer: "The framework or programming language that we use to develop applications depends upon the chosen platform by the client. For example, for iPhone applications, we use iOS SDK, Objective C, Swift, Xcode, and other tools and technologies provided by Apple. Similarly, for Android applications, we use Android SDK. We have expertise in frameworks of Open Source and Microsoft Technologies for Web Service and API based applications."
    },
    {
      question: "Appearing at the top of Google is all about money isn't it?",
      answer: "Contrary to popular belief, ranking first in Google has nothing to do with money! Put simply, you can’t pay to be first. While large marketing budgets help when it comes to SEO, as you can do more, it doesn’t guarantee results. More specialist smaller sites or sites creating great content can often outrank bigger companies." +
        "All the search engines do offer paid advertising models, where you can pay to appear higher in the paid results, but these have no impact on the natural search results."
    }
  ];

  return (
    <AnimationRevealPage>
      <HeroContainer>
      <StyledHeader links={navLinks} />
        <Faq faqs={defaultFaqs}/>
      </HeroContainer>
      <Footer />
    </AnimationRevealPage>
  );
};
