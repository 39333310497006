import React, { lazy, Suspense } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import serverIlustrationSrc from "images/server-redundancy-illustration.svg";

const MarketingFeatures = lazy(() => import("components/cards/ThreeColContactDetails.js"));
const ContactUsForm = lazy(() => import("components/forms/TwoColContactUsWithIllustrationFullForm.js"));
const Footer = lazy(() => import("components/footers/MiniCenteredFooter.js"));
const TrackRecords = lazy(() => import("components/features/TwoColSingleFeatureWithStats.js"));
const FAQ = lazy(() => import("components/faqs/SimpleWithSideImage.js"));

const renderLoader = () => <p>Loading</p>;

window.addEventListener("beforeunload", () => {
  // Production code would also be considerate of localStorage size limitations
  // and would do a LRU cache eviction to maintain sanity on storage.
  // There should also be a way to clear this data when the user signs out
  window.localStorage.setItem(
    `lastKnown_${window.location.href}`,
    JSON.stringify({
      data: document.getElementById("content").innerHTML
    })
  );
});

const headingApp = "Web App Development ";
const headingMarketing = "Digital Marketing ";
const subheadingApp = "Features";
const subheadingMarketing= "Features";


const marketingCards = [
  {
    title: "Earning Traffic",
    description: "Our marketer’s partner with you to identify your target market and get to work making your site more visible to your key market through our comprehensive digital marketing services."
  },
  { 
    title: "Measurable Advertising",
    description: "By focusing on ROI from the starting line, our marketing team crafts PPC, display, geotargeted and remarketing campaigns engineered for success."
  },
  { 
    title: "Design that dazzles",
    description: "By focusing on the latest web standards, the Sellectric interactive team lays the creative groundwork for your digital presence with a cutting-edge website design."
  },
  { 
    title: "Expand your social reach",
    description: "Our social media team are experts in creating and executing a social media management plan that gets the right message in front of your desired audience."
  },
];

const appCards = [
  {
    title: "Web Development",
    description: "We’ve mastered the top web technologies to deliver projects that have raked in investments. A trusted market leader in developing high performing web fronts for every business."
  },
  { 
    title: "Website Design",
    description: "By focusing on ROI from the starting line, our marketing team crafts PPC, display, geotargeted and remarketing campaigns engineered for success."
  },
  { 
    title: "Mobile App Development",
    description: "We build mobile apps that are loved by users. Taking the benefits of Artificial intelligence, ML, IoT and other high-end technologies to create app masterpiece that sustains."
  },
  { 
    title: "Software Testing & QA",
    description: "We leverage the most innovative types of manual and automated testing to deliver quality software products and solutions for virtually every business"
  },
];

const marketingStatistics = [
  {
    key: "Qualified Leads Generated",
    value: "271,748+"
  },
  {
    key: "Ecommerce Transactions",
    value: "550,486+"
  },
  {
    key: "Cient Testimonials",
    value: " 345+"
  }
];

const marketingDescription = "Power your business to new heights with our award winning digital marketing services and technology platform";
const marketingHeading = "Digital Marketing Services";

export default () => (

  <AnimationRevealPage>
    <Hero />
    <Suspense fallback={renderLoader()}>
      <TrackRecords statistics={null} textOnLeft={false} imageSrc={serverIlustrationSrc}/>
      <MarketingFeatures cards={appCards} heading={headingApp} subheading={subheadingApp} />      
      <TrackRecords statistics={marketingStatistics} heading={marketingHeading} textOnLeft={true} description={marketingDescription}/>
      <MarketingFeatures cards={marketingCards} heading={headingMarketing} subheading={subheadingMarketing}/>
      <FAQ
        imageSrc={customerSupportIllustrationSrc}
        imageContain={true}
        imageShadow={false}
        subheading="FAQs"
        heading={
          <>
            Do you have <span tw="text-primary-500">Questions ?</span>
          </>
        }
      />
      <ContactUsForm />
      <Footer />
    </Suspense>
  </AnimationRevealPage>
);
